import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { makeStyles } from "@material-ui/core";
import { RevenueCard } from "./revenueCard";
import { Chart } from "./chart";
import { Target } from "./target";
import { SaleStats } from "./saleStats";
import nFormatter from "../../../utils/numberToStringConverter";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { apiConfig } from "../../../services/api/apiConfig";
import { Service } from "../../../services/api/service";
import { ICountry } from "../../../interfaces";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  simple_card_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    "& div:last-child": {
      marginRight: "0px !important",
    },
  },
  simple_card: {
    padding: "15px 30px",
  },
  simple_card_title: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "26px",
    marginBottom: "5px",
  },
  simple_card_result_container: {
    display: "inline-block",
  },
  simple_card_result: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "24px",
  },
  sales_container: {},
  sales_title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "30px",
    marginTop: "50px",
  },
  sales_charts_container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
}));

interface Props {
  data: any;
  getHighestPriorityQuarterlyData: () => any;
  getHighestPriorityYearlyData: (property_name: string, key?: string, item_id?: number) => any;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  editModalFunctions: ICompanyEditModalFunctions;
}

export type AnnualRevenue = {
  sourceId: number;
  value: number;
  year?: number;
  currency: string;
};
export type QuarterlyRevenue = {
  sourceId: number;
  value: number;
  year?: number;
  quarter?: number;
  currency?: string;
};

export function getLatestAnnualRevenue(
  yearly_revenue:
    | {
        value: Array<{
          company_id: number;
          data_source_id: number;
          dataSourceId: number;
          id: number;
          include: number;
          value: number;
          year: number;
          currency: string;
        }>;
      }
    | undefined
): AnnualRevenue | null {
  if (!yearly_revenue || !Array.isArray(yearly_revenue.value)) {
    return null;
  }

  let latestYear = -1;
  let latestYearRevenue: AnnualRevenue | null = null;
  for (let i = 0; i < yearly_revenue.value.length; i++) {
    const yearly = yearly_revenue.value[i];
    if (yearly && yearly.year > latestYear) {
      latestYear = yearly.year;
      latestYearRevenue = {
        value: yearly.value,
        year: yearly.year,
        sourceId: yearly.data_source_id ?? yearly.dataSourceId,
        currency: yearly.currency,
      };
    }
  }

  return latestYearRevenue;
}

export function getLatestQuarterlyRevenue(
  quarterly_revenue:
    | {
        sourceId: number;
        value: Array<{
          company_id: number;
          data_source_id: number;
          id: number;
          include: number;
          value: number;
          year: number;
          quarter: number;
        }>;
      }
    | undefined
): QuarterlyRevenue | null {
  if (!quarterly_revenue || !Array.isArray(quarterly_revenue.value)) {
    return null;
  }

  let latestYear = -1;
  let latestQuarter = -1;
  let latestYearRevenue: QuarterlyRevenue | null = null;
  for (let i = 0; i < quarterly_revenue.value.length; i++) {
    const quarterly = quarterly_revenue.value[i];
    if (
      quarterly &&
      (quarterly.year > latestYear || (quarterly.year === latestYear && quarterly.quarter > latestQuarter))
    ) {
      latestYear = quarterly.year;
      latestQuarter = quarterly.quarter;
      latestYearRevenue = {
        value: quarterly.value,
        year: quarterly.year,
        sourceId: quarterly.data_source_id,
        quarter: quarterly.quarter,
      };
    }
  }

  return latestYearRevenue;
}

const getShortenedYear = (year: number) => {
  return year % 100 < 10 ? `0${year % 100}` : year % 100;
};
const getSortedQuarterlyRevenue = (arr: Array<any>) => {
  if (!Array.isArray(arr)) return [];
  const arr1 = arr.map((item: any) => ({
    ...item,
    label: `Q${item.quarter}-${getShortenedYear(item.year)}`,
  }));
  arr1.sort((a: any, b: any) => {
    if (a.year === b.year) {
      return a.quarter - b.quarter;
    }
    return a.year - b.year;
  });
  return arr1;
};
export const RevenueRange: FC<Props> = ({
  data,
  editModalFunctions,
  getHighestPriorityQuarterlyData,
  getHighestPriorityYearlyData,
  getDataSource,
}) => {
  const classes = useStyles();
  const [countryId, setCountryId] = useState<Record<string, number>>({});
  const { token } = useAuth();

  useEffect(() => {
    const countries_iso = ["USA", "CAN"];

    Service.get(apiConfig.countries, token as string).then((response) => {
      countries_iso.forEach((iso: string) => {
        const country = response?.data?.find((country: ICountry) => country?.iso3 === iso);
        const data: Record<string, number> = countryId;
        data[country.iso3] = country.id;
        setCountryId(data);
      });
    });
  }, []);

  const revenuesFromEdit = getHighestPriorityYearlyData("yearly_revenue");
  const latestRevenueFromEdit = getLatestAnnualRevenue({ value: revenuesFromEdit });

  const latestAnnualRevenue = latestRevenueFromEdit ?? getLatestAnnualRevenue(data?.yearly_revenue);
  const annualRevenueRaw: AnnualRevenue | null = data?.revenue ?? null;
  const annualRevenue: AnnualRevenue | null = latestAnnualRevenue ?? annualRevenueRaw;

  return (
    <div className={classes.container}>
      {!annualRevenue && !data?.revenue_by_country_yearly && !data?.stock_exchange && !data?.sales && (
        <div className={classes.empty}>No Data.</div>
      )}
      <div className={classes.simple_card_container}>
        {annualRevenue && (
          <RevenueCard>
            <div className={classes.simple_card}>
              <div className={classes.simple_card_title}>Annual Global Revenue</div>
              <div className={classes.simple_card_result}>
                {nFormatter(annualRevenue.value) + (annualRevenue.year ? ` (${annualRevenue.year})` : "")}
              </div>
            </div>
          </RevenueCard>
        )}
        {(data?.revenue_by_country_yearly?.value?.filter((item: any) => item.country.name === "Canada")?.length > 0 ||
          getHighestPriorityYearlyData("revenue_by_country_yearly")?.value?.filter(
            (item: any) => item.country.name === "Canada"
          )?.length > 0) && (
          <RevenueCard>
            <div className={classes.simple_card}>
              <div className={classes.simple_card_title}>Sum of Canada Yearly Revenue</div>
              <div className={classes.simple_card_result}>
                {getHighestPriorityYearlyData("revenue_by_country_yearly")?.value?.filter(
                  (item: any) => item.country.name === "Canada"
                )?.length > 0
                  ? `${nFormatter(
                      getHighestPriorityYearlyData("revenue_by_country_yearly")?.value?.filter(
                        (item: any) => item.country.name === "Canada"
                      )[0].value
                    )} (${
                      getHighestPriorityYearlyData("revenue_by_country_yearly")?.value?.filter(
                        (item: any) => item.country.name === "Canada"
                      )[0].year
                    })`
                  : `${nFormatter(data?.revenue_by_country_yearly?.value[0]?.value)} (${
                      data?.revenue_by_country_yearly?.value[0]?.year
                    })`}
              </div>
            </div>
          </RevenueCard>
        )}
        {data?.stock_exchange && (
          <RevenueCard>
            <div className={classes.simple_card}>
              <div className={classes.simple_card_title}>Stock Exchange</div>
              <div className={classes.simple_card_result_container}>
                <div className={classes.simple_card_result}>{data?.stock_exchange?.value?.value} $</div>
              </div>
            </div>
          </RevenueCard>
        )}
      </div>
      {data?.yearly_revenue?.value?.length > 0 || data?.quarterly_revenue?.value?.length > 0 ? (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {data?.yearly_revenue?.value?.length > 0 ? (
            <Chart
              title="Revenue History Yearly"
              data={{
                yearly: getHighestPriorityYearlyData("yearly_revenue") //To Do change property_name after backend changes
                  ? {
                      sourceId: getDataSource(getHighestPriorityYearlyData("yearly_revenue")[0]?.data_source_id)
                        ?.endpoint,
                      value: getHighestPriorityYearlyData("yearly_revenue")?.map((item: any) => ({
                        ...item,
                        label: item.year,
                      })),
                    }
                  : {
                      ...data?.yearly_revenue,
                      value: data?.yearly_revenue?.value?.map((item: any) => ({ ...item, label: item.year })),
                    },
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : null}
          {data?.quarterly_revenue?.value?.length > 0 ? (
            <Chart
              title="Revenue History Quarterly"
              data={{
                quarterly: getHighestPriorityQuarterlyData()
                  ? {
                      sourceId: getDataSource(getHighestPriorityQuarterlyData()[0]?.data_source_id)?.endpoint,
                      value: getHighestPriorityQuarterlyData()?.map((item: any) => ({
                        ...item,
                        label: `Q${item?.quarter}-${getShortenedYear(item.year)}`,
                      })),
                    }
                  : {
                      ...data?.quarterly_revenue,
                      value: getSortedQuarterlyRevenue(data?.quarterly_revenue?.value),
                    },
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : null}
        </div>
      ) : null}
      {data?.yearly_revenue_chart_data?.Canada ||
      data?.quarterly_revenue_chart_data?.Canada ||
      (data?.yearly_revenue_chart_data && data?.yearly_revenue_chart_data["United States"]) ||
      (data?.quarterly_revenue_chart_data && data?.quarterly_revenue_chart_data["United States"]) ? (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {data?.quarterly_revenue_chart_data?.Canada ? (
            <Chart
              title="Revenue History Canada Quarterly"
              data={{
                ...(data?.quarterly_revenue_chart_data?.Canada
                  ? {
                      "Canada-Quarterly": {
                        sourceId: 2,
                        value: data?.quarterly_revenue_chart_data?.Canada?.map((item: any) => ({
                          ...item,
                          label: `Q${item.quarter}-${getShortenedYear(item.year)}`,
                        })),
                      },
                    }
                  : {}),
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : data?.yearly_revenue_chart_data?.Canada ? (
            <Chart
              title="Revenue History Canada Yearly"
              data={{
                ...(data?.yearly_revenue_chart_data?.Canada
                  ? {
                      "Canada-Yearly": {
                        sourceId: 2,
                        value: data?.yearly_revenue_chart_data?.Canada?.map((item: any) => ({
                          ...item,
                          label: item.year,
                        })),
                      },
                    }
                  : {}),
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : null}
          {data?.quarterly_revenue_chart_data && data?.quarterly_revenue_chart_data["United States"] ? (
            <Chart
              title="Revenue History United States Quarterly"
              data={{
                ...(data?.quarterly_revenue_chart_data && data?.quarterly_revenue_chart_data["United States"]
                  ? {
                      "United States-Quarterly": {
                        sourceId: 2,
                        value: data?.quarterly_revenue_chart_data["United States"]?.map((item: any) => ({
                          ...item,
                          label: `Q${item.quarter}-${getShortenedYear(item.year)}`,
                        })),
                      },
                    }
                  : {}),
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : data?.yearly_revenue_chart_data && data?.yearly_revenue_chart_data["United States"] ? (
            <Chart
              title="Revenue History United States Yearly"
              data={{
                ...(data?.yearly_revenue_chart_data && data?.yearly_revenue_chart_data["United States"]
                  ? {
                      "United States-Yearly": {
                        sourceId: 2,
                        value: data?.yearly_revenue_chart_data["United States"]?.map((item: any) => ({
                          ...item,
                          label: item.year,
                        })),
                      },
                    }
                  : {}),
              }}
              editModalFunctions={editModalFunctions}
              property_name="revenue"
            />
          ) : null}
        </div>
      ) : null}
      <div className={classes.sales_container}>
        {data?.sales && (
          <>
            <div className={classes.sales_title}>Sales</div>
            <div className={classes.sales_charts_container}>
              {data?.sales?.inStore && (
                <Chart
                  title="In Store"
                  data={
                    getHighestPriorityYearlyData("sales")
                      ? {
                          sourceId: getDataSource(getHighestPriorityYearlyData("sales")[0]?.data_source_id)?.endpoint,
                          value: getHighestPriorityYearlyData("sales"),
                        }
                      : data?.sales?.inStore
                  }
                  editModalFunctions={editModalFunctions}
                  property_name="sales"
                />
              )}
              {data?.sales?.online && (
                <Chart
                  title="Online"
                  data={
                    getHighestPriorityYearlyData("sales")
                      ? {
                          sourceId: getDataSource(getHighestPriorityYearlyData("sales")[0]?.data_source_id)?.endpoint,
                          value: getHighestPriorityYearlyData("sales"),
                        }
                      : data?.sales?.online
                  }
                  editModalFunctions={editModalFunctions}
                  property_name="sales"
                />
              )}
              {data?.targets?.value?.map((target: any, index: number) => (
                <Target title={target?.title} data={target} key={index} />
              ))}
            </div>
          </>
        )}
      </div>
      <div>
        {data?.sales && (
          <>
            <div className={classes.sales_title}>Sales per divisions</div>
            <div className={classes.sales_charts_container}>
              <SaleStats data={data} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
