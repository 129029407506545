import { FC, useEffect, useState } from "react";
import { FeatureCard } from "../featureCard";
import dollarLogo from "../../../assets/dollar-circle.svg";
import shopLogo from "../../../assets/shop.svg";
import peopleLogo from "../../../assets/people.svg";
import calendarLogo from "../../../assets/calendar.svg";
import profileLogo from "../../../assets/profile.svg";
import globalLogo from "../../../assets/global.svg";
import locationLogo from "../../../assets/location.svg";
import buildingLogo from "../../../assets/building.svg";
import { CompanyViewTable } from "../companyViewTable";
import nFormatter from "../../../utils/numberToStringConverter";
import dateFormatter from "../../../utils/dateConverter";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { IListData } from "../../company-edit/ListFieldEditModal";
import { ICompany, IDataItem } from "../../../interfaces";
import { IEditFieldModalProp, ISingleData } from "../../company-edit/SingleFieldEditModal";
import { ITableData } from "../../company-edit/TableFieldEditModal";
import { ISelectData } from "../../../pages/companyView/types";
import { ShowDataSources } from "../ShowDatasources";
import {
  AnnualRevenue,
  QuarterlyRevenue,
  getLatestAnnualRevenue,
  getLatestQuarterlyRevenue,
} from "../revenue-range/revenueRange";
import { formatIndustry } from "../../../utils/industries";
import { IndustryCodesTable } from "./industryCodesTable";
import { IndustryTypesTable } from "./industryTypesTable";
import { FinancialsSection } from "./FinancialsSection";
import { useStyles } from "./styles";

interface Props {
  data: any;
  editDataForFields: Record<string, IEditFieldModalProp<ISelectData | ISingleData | IListData | ITableData> | null>;
  selectedItems: Record<string, number[]>;
  isEdit: boolean;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  editModalFunctions: ICompanyEditModalFunctions;
  getHighestPriorityData: (property_name: string) => any;
  getHighestPriorityDataIndustry: () => any;
  getHighestPriorityDataHQAddress: () => any;
  getHighestPriorityDataList: (property_name: string) => any;
  getHighestPriorityYearlyData: (property_name: string, key?: string, item_id?: number) => any;
  getHighestPriorityQuarterlyData: (property_name?: string, key?: string, item_id?: number) => any;
}

const industryRules = new Intl.PluralRules("en-US");
const industryRuleTitle: Record<Intl.LDMLPluralRule, string> = {
  zero: "Industries",
  one: "Industry",
  two: "Industries",
  few: "Industries",
  many: "Industries",
  other: "Industries",
};

function getSecondaryIndustriesTitleCount(count: number): string {
  const rule = industryRules.select(count);

  return `${count} ${industryRuleTitle[rule]}`;
}

export const CompanyOverview: FC<Props> = ({
  data,
  editDataForFields,
  selectedItems, // TO DO: update this data according to different selected items
  isEdit,
  getDataSource,
  editModalFunctions,
  getHighestPriorityData,
  getHighestPriorityDataIndustry,
  getHighestPriorityDataHQAddress,
  getHighestPriorityDataList,
  getHighestPriorityYearlyData,
  getHighestPriorityQuarterlyData,
}) => {
  const classes = useStyles();
  const [hqAddress, setHqAddress] = useState<any>({ sourceId: null, value: null });

  const primaryIndustryInEditForm = getHighestPriorityDataIndustry();
  const primaryIndustryField = primaryIndustryInEditForm
    ? primaryIndustryInEditForm.data
    : data.primaryIndustry ?? null;

  const primaryIndustry = primaryIndustryField ? formatIndustry(primaryIndustryField) : null;

  useEffect(() => {
    formHqAddress();
  }, [data?.headquarter_address]);

  const formHqAddress = () => {
    let addressValue = "";
    if (data?.headquarter_address?.value?.hq_address)
      addressValue += `${data?.headquarter_address?.value?.hq_address}, `;
    if (data?.headquarter_address?.value?.state) addressValue += `${data?.headquarter_address?.value?.state}, `;
    if (data?.headquarter_address?.value?.country) addressValue += `${data?.headquarter_address?.value?.country}, `;
    if (data?.headquarter_address?.value?.zip) addressValue += `${data?.headquarter_address?.value?.zip}`;

    if (addressValue.endsWith(", ")) addressValue = addressValue.substring(0, addressValue.length - 2);

    setHqAddress({ sourceId: data?.headquarter_address?.sourceId, value: addressValue });
  };

  const isicClassificationRaw = editDataForFields.isicClassification?.data ?? data?.isicClassification;
  const naceClassificationRaw = editDataForFields.naceClassification?.data ?? data?.naceClassification;
  const naicsClassificationRaw = editDataForFields.naicsClassification?.data ?? data?.naicsClassification;

  const yearlyRevenueData = editDataForFields.yearly_revenue;
  const latestAnnualRevenueFromEdit = yearlyRevenueData
    ? yearlyRevenueData.data
        .map((it: any) => {
          return {
            ...it,
            weight: yearlyRevenueData.dataSources.find((source) => source.id === it.dataSourceId)?.priority ?? -100,
          };
        })
        .sort((a, b) => {
          return b.year * 1000 + b.weight - a.year * 1000 + a.weight;
        })[0] ?? null
    : null;

  const latestAnnualRevenue = latestAnnualRevenueFromEdit ?? getLatestAnnualRevenue(data?.yearly_revenue);
  const annualRevenueRaw: AnnualRevenue | null = data?.revenue ?? null;
  const annualRevenue: AnnualRevenue | null = latestAnnualRevenue ?? annualRevenueRaw;

  const latestQuarterlyRevenue = getLatestQuarterlyRevenue(data.quarterly_revenue);
  const quarterlyRevenue: QuarterlyRevenue | null = latestQuarterlyRevenue;

  const yearly_revenue = getHighestPriorityYearlyData("yearly_revenue") ?? data?.yearly_revenue?.value ?? [];
  const yearly_revenue_sorted = yearly_revenue.toSorted
    ? yearly_revenue.toSorted((a: IDataItem, b: IDataItem) => {
        if (a && a.year && b && b.year) {
          return b.year - a.year;
        }
      })
    : yearly_revenue;

  const stockItem = (getHighestPriorityData("stock") || data?.stock) ?? null;
  const stock = stockItem
    ? {
        sourceId: stockItem.sourceId,
        stock: stockItem.value,
        value: stockItem.value.ticker + " / " + stockItem.value.exchange,
        details: stockItem.value.ticker + " / " + stockItem.value.exchange,
      }
    : null;

  const ownershipType = (getHighestPriorityData("ownershipType") || data?.ownershipType) ?? null;

  return (
    <div className={classes.container}>
      <div onClick={editModalFunctions.openSummaryEdit}>
        {/* Tooltip that shows data source for summary */}
        <ShowDataSources
          data={{ sourceId: data?.summary?.sourceId }}
          getDataSource={getDataSource}
          // title={
          //   data?.summary?.sourceId ? (
          //     <p className={classes.tooltip}>
          //       This data is from
          //       {" " +
          //         getDataSource(
          //           getHighestPriorityData("summary")
          //             ? getHighestPriorityData("summary")?.sourceId
          //             : data?.summary?.sourceId
          //         )?.name}
          //     </p>
          //   ) : (
          //     <p className={classes.tooltip}>No data source is found</p>
          //   )
          // }
          // placement="top"
          // arrow
        >
          <p className={classes.summaryText}>
            {getHighestPriorityData("summary")
              ? getHighestPriorityData("summary")?.value
              : data?.summary?.value
              ? data?.summary?.value
              : isEdit
              ? ""
              : "No Data"}
          </p>
        </ShowDataSources>
      </div>
      <div className={classes.keyInformations}>
        <div className={classes.keyInformationTitle}>Key Information</div>
        <div>
          <div className={classes.sectionTitle}>Business</div>
          <div className={classes.sectionContainer}>
            {isEdit || stock !== null ? (
              <FeatureCard
                title="Stock Ticker"
                logo={dollarLogo}
                data={stock}
                isEdit={isEdit}
                clicked={editModalFunctions.openStockAndOwnershipTypeEdit}
                getDataSource={getDataSource}
                sourceIds={[stock?.sourceId]}
                contentSize="m"
                contentTooltip
              />
            ) : null}
            {isEdit || ownershipType !== null ? (
              <FeatureCard
                title="Ownership Type"
                logo={dollarLogo}
                data={{ value: ownershipType.value.name }}
                isEdit={isEdit}
                clicked={editModalFunctions.openStockAndOwnershipTypeEdit}
                getDataSource={getDataSource}
                sourceIds={[ownershipType?.sourceId]}
                contentSize="m"
              />
            ) : null}
            {!primaryIndustry && !isEdit ? null : (
              <FeatureCard
                title="Primary Industry"
                data={primaryIndustry}
                logo={dollarLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openIndustryEdit}
                getDataSource={getDataSource}
                sourceIds={primaryIndustry?.sourceId ? [primaryIndustry.sourceId] : []}
                contentSize="m"
              />
            )}

            {annualRevenue && !isEdit ? (
              <FeatureCard
                title="Annual Global Revenue"
                titleSize="s"
                data={annualRevenue}
                seperator
                logo={dollarLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openYearlyRevenueEdit}
                sourceIds={[annualRevenue.sourceId]}
                getDataSource={getDataSource}
                showYear={annualRevenue && !!annualRevenue.year}
                currency={annualRevenue.currency}
              />
            ) : isEdit ? (
              <FeatureCard
                title="Annual Global Revenue"
                titleSize="s"
                data={
                  getHighestPriorityYearlyData("yearly_revenue") && getHighestPriorityYearlyData("yearly_revenue")[0]
                    ? getHighestPriorityYearlyData("yearly_revenue")[0]
                    : annualRevenue
                }
                seperator
                logo={dollarLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openYearlyRevenueEdit}
                sourceIds={[
                  getHighestPriorityYearlyData("yearly_revenue") && getHighestPriorityYearlyData("yearly_revenue")[0]
                    ? getHighestPriorityYearlyData("yearly_revenue")[0].data_source_id
                    : annualRevenue
                    ? annualRevenue.sourceId
                    : null,
                ]}
                getDataSource={getDataSource}
                showYear={!!annualRevenue?.year}
                currency={annualRevenue?.currency || data?.currency?.value?.sign}
              />
            ) : null}
            {(data?.store_num === null || data?.store_num === undefined) &&
            !isEdit &&
            !getHighestPriorityYearlyData("stores") ? null : (
              <FeatureCard
                title="Number of stores"
                data={
                  getHighestPriorityYearlyData("stores") ? getHighestPriorityYearlyData("stores")[0] : data?.store_num
                }
                seperator
                logo={shopLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openStoresEdit}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityYearlyData("stores")
                    ? getHighestPriorityYearlyData("stores")[0]?.sourceId |
                      getHighestPriorityYearlyData("stores")[0]?.data_source_id
                    : data?.store_num?.sourceId,
                ]}
              />
            )}
            {!(data as ICompany).secondaryIndustries?.length && !isEdit ? null : (
              <FeatureCard
                title="Sec. Industries"
                logo={dollarLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openSecondaryIndustriesEdit}
                getDataSource={getDataSource}
                data={
                  getHighestPriorityDataList("secondary_industries")
                    ? {
                        sourceId: getHighestPriorityDataList("secondary_industries").sourceId,
                        value: getSecondaryIndustriesTitleCount(
                          (getHighestPriorityDataList("secondary_industries").value ?? []).filter(
                            (item: IListData) => item.include === true
                          ).length
                        ),
                      }
                    : {
                        sourceId: (data as ICompany).secondaryIndustries?.at(0)?.sourceId, // TO DO: get highest priority (?)
                        value: getSecondaryIndustriesTitleCount((data as ICompany).secondaryIndustries?.length ?? 0),
                      }
                }
                sourceIds={
                  getHighestPriorityDataList("secondary_industries")
                    ? getHighestPriorityDataList("secondary_industries")
                        .value?.filter((item: IListData) => item.include === true)
                        .map((item: IListData) => item.data_source_id)
                    : (data as ICompany).secondaryIndustries?.map((item) => item.sourceId) ?? []
                }
              />
            )}
            {(data?.employee_num === null || data?.employee_num === undefined) &&
            !isEdit &&
            !getHighestPriorityYearlyData("employee_num") ? null : ( // TODO: HISTORICAL VALUE
              <FeatureCard
                title="Employees"
                data={
                  getHighestPriorityYearlyData("employee_num")
                    ? getHighestPriorityYearlyData("employee_num")[0]
                    : data?.employee_num
                }
                logo={peopleLogo}
                seperator
                isEdit={isEdit}
                clicked={editModalFunctions.openEmployeesEdit}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityYearlyData("employee_num")
                    ? getHighestPriorityYearlyData("employee_num")[0]?.data_source_id
                    : data?.employee_num?.sourceId,
                ]}
              />
            )}
            {(data?.rtn_member === null || data?.rtn_member === undefined) &&
            !isEdit &&
            !getHighestPriorityData("rtn_member") ? null : (
              <FeatureCard
                title="RTN Member"
                data={
                  getHighestPriorityData("rtn_member") === undefined
                    ? data?.rtn_member
                    : getHighestPriorityData("rtn_member")
                }
                logo={peopleLogo}
                isEdit={isEdit}
                isDataBoolean
                clicked={editModalFunctions.openRTNMember}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityData("rtn_member")
                    ? getHighestPriorityData("rtn_member").sourceId
                    : data?.rtn_member?.sourceId,
                ]}
              />
            )}

            {(data?.p2pi_member === null || data?.p2pi_member === undefined) &&
            !isEdit &&
            !getHighestPriorityData("p2pi_member") ? null : (
              <FeatureCard
                title="P2P Member"
                data={
                  getHighestPriorityData("p2pi_member") === undefined
                    ? data?.p2pi_member
                    : getHighestPriorityData("p2pi_member")
                }
                logo={peopleLogo}
                isEdit={isEdit}
                isDataBoolean
                clicked={editModalFunctions.openP2PMember}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityData("p2pi_member")
                    ? getHighestPriorityData("p2pi_member").sourceId
                    : data?.p2pi_member?.sourceId,
                ]}
              />
            )}
          </div>
        </div>
        {data.industryTags && Array.isArray(data.industryTags) && data.industryTags.length > 0 && (
          <>
            <div className={classes.sectionTitle}>Industry Tags</div>
            <div className={classes.sectionContainer}>
              <div className={classes.industryTagsContainer}>
                {data.industryTags.map((industryTag: { id: number; name: string; sourceId: number }) => {
                  const dataSource = getDataSource(industryTag.sourceId) ?? { name: "unknown source" };
                  return (
                    <ShowDataSources
                      data={{ sourceId: dataSource.id }}
                      getDataSource={getDataSource}
                      // key={industryTag.id}
                      // title={<p className={classes.tooltip}>This data is from {dataSource.name}</p>}
                      // placement="top"
                      // arrow
                    >
                      <span className={classes.industryTag}>{industryTag.name}</span>
                    </ShowDataSources>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className={classes.sectionContainer}>
          <div className={classes.industryTablesContainer}>
            <IndustryTypesTable
              data={data}
              editDataForFields={editDataForFields}
              editModalFunctions={editModalFunctions}
              isEdit={isEdit}
            />
            <IndustryCodesTable
              naicsData={naicsClassificationRaw}
              isicData={isicClassificationRaw}
              naceData={naceClassificationRaw}
              isEdit={isEdit}
              getDataSource={getDataSource}
              editModalFunctions={editModalFunctions}
            />
          </div>
        </div>
        <div>
          <div className={classes.sectionTitle}>History</div>
          <div className={classes.sectionContainer}>
            {(data?.foundationYear === null || data?.foundationYear === undefined) &&
            !isEdit &&
            !getHighestPriorityData("foundation_year") ? null : (
              <FeatureCard
                title="Date Founded"
                data={
                  getHighestPriorityData("foundation_year")
                    ? getHighestPriorityData("foundation_year")
                    : data?.foundationYear
                }
                date
                logo={calendarLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openDateOfCreationEdit}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityData("foundation_year")
                    ? getHighestPriorityData("foundation_year").sourceId
                    : data?.foundationYear?.sourceId,
                ]}
              />
            )}

            {(data?.founders === null || data?.founders === undefined) &&
            !isEdit &&
            !getHighestPriorityDataList("founders") ? null : (
              <FeatureCard
                title={data?.founders?.value?.length === 1 ? "Founder" : "Founders"}
                contentSize="s"
                data={
                  getHighestPriorityDataList("founders")
                    ? {
                        sourceId: getHighestPriorityDataList("founders")?.sourceId,
                        value: getHighestPriorityDataList("founders")?.value.length
                          ? getHighestPriorityDataList("founders")
                              ?.value.map?.((founder: IListData) => founder.full_name)
                              ?.reduce((previous: string, next: string) => previous + ", " + next)
                          : getHighestPriorityDataList("founders")?.value.map?.(
                              (founder: IListData) => founder.full_name
                            ),
                      }
                    : {
                        sourceId: data?.founders?.sourceId,
                        value: data?.founders?.value
                          ?.map((founder: any) => founder.full_name)
                          ?.reduce((previous: string, next: string) => previous + ", " + next),
                      }
                }
                logo={profileLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openFoundersEdit}
                getDataSource={getDataSource}
                sourceIds={
                  getHighestPriorityDataList("founders")
                    ? getHighestPriorityDataList("founders")
                        .value.filter((item: any) => item.include)
                        .map((item: any) => item?.sourceId | item?.data_source_id)
                    : data?.founders?.value?.map((item: any) => item.sourceId)
                }
              />
            )}
            {/* {(data?.chairman === null || data?.chairman === undefined) &&
            !isEdit &&
            !getHighestPriorityData("chairman") ? null : (
              <FeatureCard
                title="Chairman"
                data={
                  getHighestPriorityData("chairman")
                    ? getHighestPriorityData("chairman")
                    : {
                        sourceId: data?.chairman?.sourceId,
                        value: data?.chairman?.value?.full_name,
                      }
                }
                logo={profileLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openChairmanEdit}
                getDataSource={getDataSource}
              />
            )} */}

            {(data?.board_members === null ||
              data?.board_members === undefined ||
              data?.board_members?.value?.length === 0) &&
            !isEdit &&
            !getHighestPriorityDataList("board_members") ? null : (
              <FeatureCard
                title="Board"
                data={
                  getHighestPriorityDataList("board_members")
                    ? {
                        sourceId: getHighestPriorityDataList("board_members")?.sourceId,
                        value:
                          (getHighestPriorityDataList("board_members")?.value?.length !== undefined
                            ? getHighestPriorityDataList("board_members")?.value?.length
                            : "0") + " People",
                      }
                    : {
                        sourceId: data?.board_members?.sourceId,
                        value:
                          (data?.board_members?.value?.length !== undefined
                            ? data?.board_members?.value?.length
                            : "0") + " People",
                      }
                }
                logo={peopleLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openBoardEdit}
                getDataSource={getDataSource}
                sourceIds={
                  getHighestPriorityDataList("board_members")
                    ? getHighestPriorityDataList("board_members")
                        .value.filter((item: any) => item.include)
                        .map((item: any) => item?.sourceId | item?.data_source_id)
                    : data?.board_members?.value?.map((item: any) => item.sourceId)
                }
              />
            )}
            {(data?.owner === null || data?.owner === undefined) &&
            !isEdit &&
            !getHighestPriorityData("owner") ? null : (
              <FeatureCard
                title="Owner"
                data={
                  getHighestPriorityData("owner")
                    ? getHighestPriorityData("owner")
                    : { sourceId: data?.owner?.sourceId, value: data?.owner?.value?.full_name }
                }
                logo={peopleLogo}
                clicked={editModalFunctions.openOwnerEdit}
                isEdit={isEdit}
                getDataSource={getDataSource}
                sourceIds={
                  getHighestPriorityData("owner")
                    ? [getHighestPriorityData("owner").sourceId | getHighestPriorityData("owner").data_source_id]
                    : [data?.owner?.sourceId]
                }
              />
            )}
            {(data?.ceo === null || data?.ceo === undefined) && !isEdit && !getHighestPriorityData("ceo") ? null : (
              <FeatureCard
                title="Ceo"
                data={
                  getHighestPriorityData("ceo")
                    ? getHighestPriorityData("ceo")
                    : {
                        sourceId: data?.ceo?.sourceId,
                        value: data?.ceo?.value?.full_name,
                      }
                }
                logo={profileLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openCeoEdit}
                getDataSource={getDataSource}
                sourceIds={
                  getHighestPriorityData("ceo")
                    ? [getHighestPriorityData("ceo").sourceId | getHighestPriorityData("ceo").data_source_id]
                    : [data?.ceo?.sourceId]
                }
              />
            )}
            {(data?.executives === null || data?.executives === undefined || data?.executives?.value?.length === 0) &&
            !isEdit &&
            !getHighestPriorityDataList("executives") ? null : (
              <FeatureCard
                title="Executives"
                data={
                  getHighestPriorityDataList("executives")
                    ? {
                        sourceId: getHighestPriorityDataList("executives")?.sourceId,
                        value:
                          (getHighestPriorityDataList("executives")?.value?.length
                            ? getHighestPriorityDataList("executives")?.value?.filter((item: any) => item.include)
                                ?.length
                            : "0") + " Person",
                      }
                    : {
                        sourceId: data?.executives?.sourceId,
                        value:
                          (data?.executives?.value?.length
                            ? data?.executives?.value?.filter((item: any) => item.include)?.length
                            : "0") + " People",
                      }
                }
                logo={peopleLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openKeyPersonsEdit}
                getDataSource={getDataSource}
                sourceIds={
                  getHighestPriorityDataList("executives")
                    ? getHighestPriorityDataList("executives")
                        .value.filter((item: any) => item.include)
                        .map((item: any) => item?.sourceId | item?.data_source_id)
                    : data?.executives?.value?.map((item: any) => item.sourceId)
                }
              />
            )}
          </div>
        </div>
        <div>
          <div className={classes.sectionTitle}>Location</div>
          <div className={classes.sectionContainer}>
            {(data?.operation_areas === null ||
              data?.operation_areas === undefined ||
              data?.operation_areas?.value?.length === 0) &&
            !isEdit ? null : (
              <FeatureCard
                title="Active Regions"
                data={{
                  sourceId: data?.operation_areas?.sourceId,
                  value:
                    data?.operation_areas?.value?.length > 3
                      ? "International"
                      : data?.operation_areas?.value?.map?.((location: any) => location.name)?.join(", "),
                }}
                logo={locationLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openOperationAreasEdit}
                getDataSource={getDataSource}
                sourceIds={
                  data?.operation_areas?.value?.length > 0
                    ? data?.operation_areas?.value?.map((item: any) => item.sourceId)
                    : []
                }
              />
            )}
            {(data?.headquarter_address === null || data?.headquarter_address === undefined) &&
            !isEdit &&
            !getHighestPriorityDataHQAddress() ? null : (
              <FeatureCard
                title="Headquarters"
                data={getHighestPriorityDataHQAddress() ? getHighestPriorityDataHQAddress() : hqAddress}
                logo={buildingLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openHeadquarterEdit}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityDataHQAddress() ? getHighestPriorityDataHQAddress().sourceId : hqAddress.sourceId,
                ]}
                contentSize="xs"
              />
            )}

            {(data?.domain_primary === null || data?.domain_primary === undefined) &&
            !isEdit &&
            !getHighestPriorityData("domain") ? null : (
              <FeatureCard
                title="Website"
                link
                linkTitle={
                  (getHighestPriorityData("domain") ? getHighestPriorityData("domain") : data?.domain_primary)?.value
                }
                data={getHighestPriorityData("domain") ? getHighestPriorityData("domain") : data?.domain_primary}
                logo={globalLogo}
                isEdit={isEdit}
                clicked={editModalFunctions.openDomainEdit}
                getDataSource={getDataSource}
                sourceIds={[
                  getHighestPriorityData("domain")
                    ? getHighestPriorityData("domain")?.sourceId
                    : data?.domain_primary?.sourceId,
                ]}
              />
            )}
          </div>
        </div>
        {((data?.linkedin !== undefined && data?.linkedin !== null) || isEdit) && (
          <div>
            <div className={classes.sectionTitle}>Web</div>
            <div className={classes.sectionContainer}>
              {(data?.linkedin === null || data?.linkedin === undefined) &&
              !isEdit &&
              !getHighestPriorityData("linkedin") ? null : (
                <FeatureCard
                  title="Social Media"
                  link
                  linkTitle="LinkedIn"
                  data={getHighestPriorityData("linkedin") ? getHighestPriorityData("linkedin") : data?.linkedin}
                  logo={globalLogo}
                  isEdit={isEdit}
                  clicked={editModalFunctions.openLinkedinEdit}
                  getDataSource={getDataSource}
                  sourceIds={[
                    getHighestPriorityData("linkedin")
                      ? getHighestPriorityData("linkedin")?.sourceId
                      : data?.linkedin?.sourceId,
                  ]}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        <CompanyViewTable
          title="Key Information"
          headers={[
            { title: "Category", width: "20%" },
            { title: "Name", width: "20%" },
            { title: "Data", width: "30%" },
            { title: "Details", width: "30%" },
          ]}
          /* There Are 5 property of each Row: firstColumn, rowSpan, rowTitle, result, details */
          rows={[
            {
              firstColumn: "Business",
              rowSpan: 3,
              rowTitle: "Industry",
              result: primaryIndustry?.value ?? "",
            },
            {
              rowTitle: "Revenue",
              result: yearly_revenue_sorted.map((el: any) => el?.year),
              details: yearly_revenue_sorted.map((el: any) => nFormatter(el?.value)),
              // currency: data?.currency?.value?.sign,
            },
            {
              rowTitle: "Employee",
              result: getHighestPriorityYearlyData("employee_num")
                ? nFormatter(getHighestPriorityYearlyData("employee_num")?.slice(0)[0]?.value)
                : nFormatter(data?.employee_num?.value),
              details: getHighestPriorityYearlyData("employee_num")
                ? getHighestPriorityYearlyData("employee_num")?.map(
                    (el: any) => el?.year + " - " + nFormatter(el?.value)
                  )
                : data?.employee_num_history?.value?.map((el: any) => el?.year + " - " + nFormatter(el.value)),
            },
            {
              firstColumn: "History",
              rowSpan: 4,
              rowTitle: "Date Founded",
              result: getHighestPriorityData("foundation_year")
                ? dateFormatter(getHighestPriorityData("foundation_year")?.value)
                : dateFormatter(data?.foundationYear?.value),
            },
            {
              rowTitle: data?.founders?.value?.length === 1 ? "Founder" : "Founders",
              result: getHighestPriorityDataList("founders")
                ? getHighestPriorityDataList("founders")?.value.map((founder: IListData) => founder.full_name)
                : data?.founders?.value?.map((el: any) => el.full_name),
            },
            ...(data?.ceo || getHighestPriorityData("ceo")
              ? [
                  {
                    rowTitle: "CEO",
                    result: getHighestPriorityData("ceo")
                      ? getHighestPriorityData("ceo")?.value
                      : data?.ceo?.value?.full_name,
                  },
                ]
              : []),
            ...(data?.owner || getHighestPriorityData("owner")
              ? [
                  {
                    rowTitle: "Owner",
                    result: getHighestPriorityData("owner")
                      ? getHighestPriorityData("owner")?.value
                      : data?.owner?.value?.full_name,
                  },
                ]
              : []),
            ...(data?.chairman || getHighestPriorityData("chairman")
              ? [
                  {
                    rowTitle: "Chairman",
                    result: getHighestPriorityData("chairman")
                      ? getHighestPriorityData("chairman")?.value
                      : data?.chairman?.value?.full_name,
                  },
                ]
              : []),
            {
              rowTitle: "Board",
              result: getHighestPriorityDataList("board_members")
                ? getHighestPriorityDataList("board_members")?.value.map((person: IListData) => person.full_name)
                : data?.board_members?.value?.map((el: any) => el.full_name),
              details: getHighestPriorityDataList("board_members")
                ? getHighestPriorityDataList("board_members")?.value.map((person: IListData) => person.title)
                : data?.board_members?.value?.map((el: any) => el.title),
            },
            {
              firstColumn: "Location",
              rowSpan: 3,
              rowTitle: "Active Regions",
              result: data.operation_areas?.value?.map((location: any) => location.name),
            },
            {
              rowTitle: "Headquarters",
              result: getHighestPriorityDataHQAddress() ? getHighestPriorityDataHQAddress()?.value : hqAddress?.value,
            },
            {
              rowTitle: "Website",
              result: getHighestPriorityData("domain")
                ? getHighestPriorityData("domain")?.value
                : data?.domain_primary?.value,
            },
          ]}
        />
      </div>

      <FinancialsSection
        data={data}
        isEdit={isEdit}
        classes={classes}
        getHighestPriorityData={getHighestPriorityData}
        getHighestPriorityYearlyData={getHighestPriorityYearlyData}
        getHighestPriorityQuarterlyData={getHighestPriorityQuarterlyData}
        editModalFunctions={editModalFunctions}
        editDataForFields={editDataForFields}
        getDataSource={getDataSource}
        selectedItems={selectedItems}
        quarterlyRevenue={quarterlyRevenue}
      />
      {/*Business Cards */}
    </div>
  );
};
