import { FC, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import { ICompany } from "../../../interfaces";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Maps } from "../googleMaps/map";
import { Banner } from "../../banner";
import { Avatar } from "../../avatar";
import { OverwriteDataSourceMappingsDialog } from "../../company-overwrite/overwriteDataSourceMappingsDialog";
import { ShowDataSources } from "../ShowDatasources";
import { formatHqAddress } from "./helpers";
import { HqAddress } from "./types";

import { useStyles } from "./styles";
import { Service } from "../../../services/api/service";
import { apiConfig } from "../../../services/api/apiConfig";

export interface CompanyBannerProps {
  data: any;
  onStatusUpdateFinish: (status: { isArchived: boolean }) => void;
  isEdit: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: () => void;
  cancelEdit: () => void;
  onRefreshFinish: () => void;
  preview: boolean;
  editModalFunctions: ICompanyEditModalFunctions;
  getHighestPriorityData: (property_name: string) => any;
  getHighestPriorityDataHQAddress: () => any;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
}

export const CompanyBanner: FC<CompanyBannerProps> = ({
  data,
  onStatusUpdateFinish,
  isEdit,
  setIsEdit,
  cancelEdit,
  onRefreshFinish,
  preview,
  editModalFunctions,
  getHighestPriorityData,
  getHighestPriorityDataHQAddress,
  getDataSource,
  setLoading,
}) => {
  const classes = useStyles();
  const [domainURL, setDomainURL] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [openOverwriteMappingsDialog, setOpenOverwriteMappingsDialog] = useState(false);
  const handleOpenOverwriteMappingsDialog = () => setOpenOverwriteMappingsDialog(true);
  const handleCloseOverwriteMappingsDialog = () => setOpenOverwriteMappingsDialog(false);
  const { hasPermission, token } = useAuth();

  const hqAddress: HqAddress = getHighestPriorityDataHQAddress()
    ? getHighestPriorityDataHQAddress()
    : formatHqAddress(data);

  const refreshDataSources = () => {
    setLoading(true);
    Service.post(`${apiConfig.companyRefreshDataSources}/${String(data.id)}/refresh-data-sources`, token as string, {})
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          cancelEdit();
          if (response.data.error) {
            setSnackbarMessage("Something went wrong");
            setOpenSnackbar(true);
          }
          if (response.data.details[0].status === "success") {
            setSnackbarMessage("Refresh done.");
            setOpenSnackbar(true);
            onRefreshFinish();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Something went wrong");
        setOpenSnackbar(true);
      });
  };

  const updateProfileStatus = () => {
    setLoading(true);
    Service.post(`${apiConfig.admin}/company-profiles/${String(data.id)}/status`, token as string, {
      status: data.isArchived ? "ACTIVE" : "ARCHIVED",
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          cancelEdit();
          onStatusUpdateFinish(response.data);
          setSnackbarMessage(`Profile ${response.data.isArchived ? "archived" : "activated"}.`);
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Something went wrong");
        setOpenSnackbar(true);
      });
  };

  useEffect(() => {
    data?.domain_primary?.value?.startsWith("http://") || data?.domain_primary?.value?.startsWith("https://")
      ? setDomainURL(data?.domain_primary?.value)
      : setDomainURL("http://" + data?.domain_primary?.value);
  }, [data?.domain_primary?.value]);

  const name = getHighestPriorityData("name")?.value ?? data?.name?.value;
  const isArchived = data.isArchived;

  const displayName = isArchived ? `${name} [ARCHIVED]` : name;

  return (
    <Banner buttonTitle="Back to all companies" location={-1}>
      <div style={{ display: "flex", position: "relative", width: "100%" }}>
        <div
          style={{ display: "flex", flexDirection: "row", position: "relative", zIndex: 2, background: "transparent" }}
        >
          <div>
            <ShowDataSources
              getDataSource={getDataSource}
              data={
                getHighestPriorityData("logo")
                  ? { sourceId: getHighestPriorityData("logo")?.sourceId }
                  : { sourceId: data?.logo?.sourceId }
              }
            >
              <div onClick={editModalFunctions.openLogoEdit}>
                <Avatar
                  logo={data?.logo?.value}
                  name={data?.name?.value}
                  style={{
                    display: "grid",
                    placeItems: "center",
                    margin: "10px 30px 50px 55px",
                    width: 240,
                    height: 240,
                    padding: 15,
                    fontSize: "36px",
                    ...(isEdit ? { cursor: "pointer" } : {}),
                  }}
                />
              </div>
            </ShowDataSources>
          </div>
          <div className={classes.companyInfo}>
            <ShowDataSources
              getDataSource={getDataSource}
              data={
                getHighestPriorityData("name")
                  ? { sourceId: getHighestPriorityData("name")?.sourceId }
                  : { sourceId: data?.name?.sourceId }
              }
            >
              <span
                className={isEdit ? `${classes.companyName} ${classes.hover}` : classes.companyName}
                onClick={editModalFunctions.openNameEdit}
                style={{ cursor: isEdit ? "pointer" : "default" }}
              >
                {displayName}
              </span>
            </ShowDataSources>
            <span className={classes.companyIndustry}>{(data as ICompany)?.primaryIndustry?.name}</span>
            <a href={domainURL} target="_blank" rel="noreferrer" className={classes.companyLink}>
              {data?.domain_primary?.value?.replace(/^https?\:\/\//i, "") || data?.name?.value || "no_domain"}
            </a>
            <div className={classes.companySubInfo}>
              {data && data.ultimateParent ? (
                <div className={classes.subsidiaryOf}>
                  <span className={classes.subsidiaryOfTitle}>Subsidiary Of</span>
                  {data.ultimateParent.id ? (
                    <a
                      href={`/companies/${data.ultimateParent.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.companyLink}
                    >
                      {data.ultimateParent.name}
                    </a>
                  ) : (
                    <span>{data.ultimateParent.name}</span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes.companyInfoWrapper}>
            <ShowDataSources data={{ sourceId: Number(hqAddress.sourceId) }} getDataSource={getDataSource}>
              <div style={{ marginRight: 30 }} className={classes.contactParent}>
                <span className={classes.addressTitle}>Address</span>
                <span
                  className={isEdit ? `${classes.address} ${classes.hover}` : classes.address}
                  onClick={editModalFunctions.openHeadquarterEdit}
                  style={{ cursor: isEdit ? "pointer" : "default" }}
                >
                  {hqAddress.value}
                </span>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${hqAddress.value}`}
                  target="_blank"
                  className={classes.companyLink}
                >
                  See in Google Map
                </a>
              </div>
            </ShowDataSources>
            <ShowDataSources data={{ sourceId: data?.phone_numbers?.sourceId }} getDataSource={getDataSource}>
              <div className={classes.contactParent}>
                <span className={classes.addressTitle}>Phone Numbers</span>
                <div
                  className={isEdit ? `${classes.contactParent} ${classes.hover}` : classes.contactParent}
                  onClick={editModalFunctions.openPhoneNumberEdit}
                  style={{ cursor: isEdit ? "pointer" : "default", maxHeight: "26px" }}
                >
                  <p className={classes.phoneNumber}>
                    {getHighestPriorityData("phone")
                      ? getHighestPriorityData("phone")?.value
                      : data?.phone_numbers?.value
                      ? data?.phone_numbers?.value
                      : isEdit
                      ? ""
                      : "No Data"}
                  </p>
                </div>
              </div>
            </ShowDataSources>
          </div>
        </div>

        <div className={classes.mapsParent}>
          <div className={classes.fadeImageContainer}>
            <Maps address={String(hqAddress.value)} className={classes.fadeImage} />
            <div className={classes.gradientOverlay}></div>
          </div>
          <div className={`${classes.buttons} no-print`}>
            {isEdit && !preview ? (
              <>
                {hasPermission("CompanyConfig.ReadWrite.All") && (
                  <button
                    className={`${classes.button} ${data.isArchived ? classes.primaryButton : classes.secondaryButton}`}
                    onClick={updateProfileStatus}
                  >
                    {data.isArchived ? "Activate" : "Archive"}
                  </button>
                )}
                {hasPermission("Company.ReadWrite.All") && (
                  <button className={`${classes.button} ${classes.editButton}`} onClick={refreshDataSources}>
                    Refresh data sources
                  </button>
                )}
              </>
            ) : (
              <>
                <button className={`${classes.button} ${classes.historyButton}`} title="action not implemented">
                  Show version history
                </button>
                {hasPermission("Company.ReadWrite") && (
                  <button className={`${classes.button} ${classes.editButton}`} onClick={setIsEdit}>
                    Edit company profile
                  </button>
                )}

                {hasPermission("Company.ReadWrite.All") && (
                  <button
                    className={`${classes.button} ${classes.editButton}`}
                    onClick={handleOpenOverwriteMappingsDialog}
                  >
                    Edit Data Sources mappings
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal open={openOverwriteMappingsDialog} onClose={handleCloseOverwriteMappingsDialog}>
        <OverwriteDataSourceMappingsDialog
          onClose={handleCloseOverwriteMappingsDialog}
          companyId={data.id}
        ></OverwriteDataSourceMappingsDialog>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={7000}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)}
      />
    </Banner>
  );
};
