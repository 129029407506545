import React, { Dispatch, SetStateAction, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import { DataSource, RevenueItem, YearlyRevenuesEditData } from "./edit-data";

import AddRowIcon from "../../../assets/add-circle.png";
import { RevenueItemCard } from "./revenue-item-card";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => {
  return {
    addRowContainer: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    addRowText: {
      color: "#4D748D",
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    addColumnIcon: {
      height: "20px",
      width: "20px",
      marginRight: "10px",
    },
  };
});

const getHighestPriorityDataSource = (yearData: RevenueItem[], dataSources: DataSource[]): DataSource | null => {
  const includedData = yearData.filter((item) => item.include);
  if (includedData.length === 0) return null;

  const highestPriorityItem = includedData.reduce((prev, curr) => {
    const prevSource = dataSources.find((ds) => ds.id === prev.dataSourceId);
    const currSource = dataSources.find((ds) => ds.id === curr.dataSourceId);
    return (prevSource?.priority || 0) > (currSource?.priority || 0) ? prev : curr;
  });

  const highestPrioritySource = dataSources.find((ds) => ds.id === highestPriorityItem.dataSourceId);
  return highestPrioritySource || null;
};

function getYearOptions(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => end - i);
}

const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = getYearOptions(2000, currentYear);

/*
opacity: 1;
color: rgba(51, 51, 51, 1);
font-family: "Montserrat-Bold";
font-size: 12px;
font-weight: 700;
font-style: Bold;
letter-spacing: 0px;
text-align: left;
line-height: 14px;
 */

const StyledTableYearCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    borderBottom: "none",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
}));

const StyledTableRevenueItemCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    borderBottom: "none",
  },
}));

type Props = {
  editData: YearlyRevenuesEditData;
  setEditData: Dispatch<SetStateAction<YearlyRevenuesEditData>>;
};

export const EditYearlyRevenuesFormTable = ({ editData, setEditData }: Props) => {
  const classes = useStyles();

  const [currentEditableYear, setCurrentEditableYear] = useState<number | null>(null);
  const [yearToAdd, setYearToAdd] = useState<number | null>(null);

  const dataSources = editData.dataSources;
  const sortedDataSources = [...dataSources].sort((a, b) => b.priority - a.priority);
  // TODO Get rid of "?? 2"
  const manualDataSourceId = dataSources.find((it) => it.type === "Manual")?.id ?? 2;

  const data = editData.data;
  const groupedData = data.reduce<Record<number, RevenueItem[]>>((acc, item) => {
    if (!acc[item.year]) {
      acc[item.year] = [];
    }
    acc[item.year].push(item as any as RevenueItem);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedData)
    .map(Number)
    .sort((a, b) => b - a);

  const toggleInclude = (year: number, dataSourceId: number) => {
    setEditData((prevState) => {
      const newData = prevState.data.map((item) => {
        if (item.year === year && item.dataSourceId === dataSourceId) {
          return { ...item, include: !item.include };
        }
        return item;
      });
      return {
        ...prevState,
        data: newData,
      };
    });
  };

  const updateRevenueItem = (year: number, dataSourceId: number, updatedFields: Partial<RevenueItem>) => {
    setEditData((prevState) => {
      const existingItem = prevState.data.find((item) => item.year === year && item.dataSourceId === dataSourceId);

      if (existingItem) {
        return {
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.year === year && item.dataSourceId === dataSourceId) {
              return { ...item, ...updatedFields };
            }
            return item;
          }),
        };
      } else {
        return {
          ...prevState,
          data: [...prevState.data, updatedFields as RevenueItem],
        };
      }
    });
  };

  const deleteRevenueItem = (year: number, dataSourceId: number) => {
    setEditData((prevState) => {
      return {
        ...prevState,
        data: prevState.data.filter((item) => !(item.year === year && item.dataSourceId === dataSourceId)),
      };
    });
  };

  const addYearRow = () => {
    if (yearToAdd) {
      const newItem: RevenueItem = {
        dataSourceId: manualDataSourceId,
        year: yearToAdd,
        id: null,
        value: 0,
        include: true,
        currency: "USD",
      };

      updateRevenueItem(yearToAdd, manualDataSourceId, newItem);
      setCurrentEditableYear(yearToAdd);
    }
  };

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 350 }}>
          <Table stickyHeader size="small" aria-label="company yearly revenues">
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell align="left">Year</StyledTableHeaderCell>
                {sortedDataSources.map((source) => (
                  <StyledTableHeaderCell key={source.id} align="left">
                    {source.name}
                  </StyledTableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedYears.map((year) => {
                const yearData = groupedData[year];
                const highestPrioritySource = getHighestPriorityDataSource(yearData, sortedDataSources);

                return (
                  <TableRow key={year} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableYearCell align="left">{year}</StyledTableYearCell>
                    {sortedDataSources.map((source) => {
                      return (
                        <StyledTableRevenueItemCell
                          key={source.id}
                          align="left"
                          sx={{
                            // fontWeight: highestPrioritySource?.id === source.id ? "bold" : "normal",
                            position: "relative",
                          }}
                        >
                          {/*item ? (
                            <>
                              {`${item.value} ${item?.currency}`}
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 4,
                                  right: 4,
                                }}
                              >
                                <Switch
                                  checked={item?.include || false}
                                  onChange={() => toggleInclude(year, source.id)}
                                />
                              </Box>
                            </>
                          ) : (
                            "-"
                          )
                          */}
                          <RevenueItemCard
                            item={yearData.find((i) => i.dataSourceId === source.id)}
                            canEdit={source.type === "Manual"}
                            canToggleInclude={source.type !== "Manual"} // You can adjust this based on your requirements
                            isHighestPriority={highestPrioritySource?.id === source.id}
                            onUpdate={updateRevenueItem}
                            onDelete={() => deleteRevenueItem(year, source.id)}
                            onToggleInclude={() => toggleInclude(year, source.id)}
                            year={year}
                            dataSourceId={source.id}
                            isEditing={currentEditableYear === year && source.type === "Manual"}
                            onStartEdit={() => setCurrentEditableYear(year)}
                            onCancelEdit={() => setCurrentEditableYear(null)}
                            onApplyEdit={(editState) => {
                              updateRevenueItem(year, source.id, {
                                value: editState.value === "" ? 0 : editState.value,
                                currency: editState.currency,
                              });
                              setCurrentEditableYear(null);
                            }}
                          ></RevenueItemCard>
                        </StyledTableRevenueItemCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div>
        <div className={classes.addRowContainer} onClick={addYearRow}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="yearly-revenues-table-add-row-input-label">Year</InputLabel>
            <Select
              size="small"
              value={yearToAdd}
              label="Year"
              labelId="yearly-revenues-table-add-row-input-label"
              onChange={(e) => setYearToAdd(e.target.value as any)}
              MenuProps={{
                anchorOrigin: { vertical: "top", horizontal: "left" },
                transformOrigin: { vertical: "bottom", horizontal: "left" },
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    overflow: "auto",
                  },
                },
              }}
            >
              <MenuItem value={null as any}>Select Year</MenuItem>
              {YEAR_OPTIONS.map((year) => (
                <MenuItem key={year} value={year} disabled={sortedYears.includes(year)}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <img src={AddRowIcon} className={classes.addColumnIcon} alt="add row" />
          <div className={classes.addRowText}>Add row</div>
        </div>
      </div>
    </div>
  );
};
